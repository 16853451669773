"use client"
import { getTheme } from "@/theme"
import { Box, Button, ButtonProps, SxProps, Theme, alpha } from "@mui/material"
import Link from "next/link"
import { PropsWithChildren, useState } from "react"

interface Props extends PropsWithChildren<ButtonProps> {
    href: string
    target?: string
}

const theme = getTheme()

export const ActionButton = ({ href, children, sx = {}, target = "_self", ...props }: Props) => {
    const style = { ...styles.button, ...sx }

    const [glowPosition, setGlowPosition] = useState({ x: 0, y: 0 })

    const handleMouseMove = (e: any) => {
        const rect = e.currentTarget.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top
        setGlowPosition({ x, y })
    }

    const glowStyle = {
        ...styles.glow,
        background: `radial-gradient(circle at ${glowPosition.x}px ${glowPosition.y}px, ${theme.palette.secondary.light}, transparent)`,
    }

    return (
        <Link href={href} target={target} style={{ textDecoration: "none" }}>
            <Button onMouseMove={handleMouseMove} {...props} sx={style} variant="contained">
                {children}
                <Box component="div" sx={glowStyle}></Box>
            </Button>
        </Link>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    glow: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        borderRadius: 1,
        zIndex: -1,
    },
    button: {
        color: alpha(theme.palette.text.primary, 0.9),
        fontSize: theme.typography.h6,
        fontWeight: 500,
        px: 4,
        py: 1.2,
        borderRadius: 1,
        position: "relative",
        border: "none",
        zIndex: 0,
        "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark}, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
            borderRadius: "inherit",
            zIndex: -2,
            boxShadow: `0 0 10px ${theme.palette.primary.dark}`,
        },
        "&:after": {
            content: '""',
            position: "absolute",
            top: 1,
            left: 1,
            right: 1,
            bottom: 1,
            borderRadius: "inherit",
            zIndex: -1,
            background: `linear-gradient(45deg, ${theme.palette.background.default}, ${alpha(
                theme.palette.background.default,
                0.8
            )})`,
        },
        "& .MuiButton-label": {
            zIndex: 1,
            position: "relative",
        },

        transition: "all 0.5s ease",
        ":hover": {
            color: theme.palette.text.primary,
            boxShadow: `0 0 10px ${theme.palette.primary.dark}`,
        },

        [theme.breakpoints.down("md")]: {
            color: theme.palette.text.primary,
            fontSize: theme.typography.body1,
            fontWeight: 600,
        },
    },
}
