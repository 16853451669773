"use client"

import { usePathname, useSearchParams } from "next/navigation"
import Script from "next/script"
import { useEffect } from "react"
import { Suspense } from "react"

interface Props {
    gtmId: string
}

function PageTracker() {
    const pathname = usePathname()
    const searchParams = useSearchParams()

    useEffect(() => {
        if (pathname) {
            // @ts-ignore
            window.dataLayer?.push({
                event: "pageview",
                page: pathname,
            })
        }
    }, [pathname, searchParams])

    return null
}

export function GTMScript({ gtmId }: Props) {
    return (
        <>
            <Suspense fallback={null}>
                <PageTracker />
            </Suspense>
            <Script
                id="gtm-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmId}');
          `,
                }}
            />
        </>
    )
}
