"use client"
import { Box, Typography, Button, Fade } from "@mui/material"
import { EmojiEvents as TrophyIcon } from "@mui/icons-material"

function Banner() {
    return (
        <Fade in={true} timeout={1000}>
            <Box
                component="div"
                sx={{
                    width: "100%",
                    padding: 1,
                    px: 2,
                    pr: 3,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: { xs: 1, md: 2 },
                    backgroundColor: (theme) => theme.palette.primary.dark,
                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight={500}
                    textAlign="center"
                    fontSize={{ xs: 14, md: 18 }}
                >
                    Recall is a finalist in Product Hunt's Golden Kitty Awards!
                </Typography>

                <Button
                    href="https://www.producthunt.com/golden-kitty-awards/personal-productivity-2"
                    target="_blank"
                    variant="contained"
                    color="primary"
                    endIcon={
                        <TrophyIcon sx={{ color: "gold", display: { xs: "none", md: "block" } }} />
                    }
                    sx={{
                        fontWeight: 500,
                        borderRadius: 0.5,
                        fontSize: 16,
                        boxShadow: "0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset",
                        background:
                            "linear-gradient(180deg, rgba(255, 255, 255, 0.06 ) 0%, rgba(255, 255, 255, 0.1) 100%)",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                            boxShadow: "0px 4px 8px rgba(255, 255, 255, 0.25) inset",
                        },
                    }}
                >
                    Vote for Us
                </Button>
            </Box>
        </Fade>
    )
}

export default Banner
